import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Typography, Box } from '@mui/material';
import Menu from '../Menu/Menu';

function Detailupdateboard() {
  const navigate = useNavigate()

  const { paramsId } = useParams();
  const id = parseInt(paramsId, 10) + 1;

  const [version, setVersion] = useState();
  const [date, setDate] = useState();
  const [description, setDescription] = useState();

  const detailSelectboard = useCallback(() => {
    fetch('https://ser.eyas.o-r.kr/select/dub', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id
      })
    })
      .then((res) => res.json())
      .then(({ status, data }) => {
        if (status) {
          console.log('test')
          // alert('데이터를 가져왔습니다.')
          setVersion(data[0].version_number)
          setDate(data[0].release_date)
          setDescription(data[0].description)
        } else {
          alert('데이터를 가져오는 데 실패했습니다.');
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        alert('서버 오류가 발생했습니다.');
      });
  }, [id])

  useEffect(() => {
    detailSelectboard();
  }, [detailSelectboard]);

  return (
    <Menu>
      <Box sx={{
        float: 'left',
        width: 'calc(100% - 260px)',
        minWidth: '98%',
        minHeight: '95%',
        marginTop: '30px',
        overflowX: 'auto',
        padding: '10px 2px',
      }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}>
          <Typography variant="h4" component="h1" gutterBottom>
            업데이트 공지
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
          >
            목록 보기
          </Button>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '30px',
          border: '1px solid black',
          borderRadius: '5px',
          height: '50px',
          padding: '10px',
        }}>
          <Typography variant="h5" component="div" gutterBottom>
            {version}
          </Typography>
          <Typography variant="h5" component="div" gutterBottom>
            {date}
          </Typography>
        </Box>
        <Typography variant="h5" component="div" gutterBottom sx={{
          marginTop: '15px',
          border: '1px solid black',
          borderRadius: '5px',
          height: '150px',
          padding: '10px'
        }}>
          {description}
        </Typography>
      </Box>
    </Menu>
  )
}

export default Detailupdateboard
