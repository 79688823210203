import React, { useCallback, useEffect, useState } from 'react';
import Menu from '../Menu/Menu';
import '../../styles/core.css';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

function ContentsList() {
  const [cl, setCl] = useState([]);
  const [filterText, setFilterText] = useState('');

  const navigate = useNavigate();

  const columnTitles = {
    ec_idx: '순번',
    ec_name: '이름',
    ec_status: '상태',
    ec_number: '발달영역',
    ec_mode: '방식',
    ec_tablet: '태블릿',
    ec_illiterate: '문맹',
    ec_guideVideo: '안내영상',
    ec_agency: '기획사',
    ec_planner: '기획자',
    ec_explanation: '설명',
  };

  const developmentAreas = {
    0: '집중력',
    1: '기억력',
    2: '지남력',
    3: '실행기능',
    4: '계산능력',
    5: '시지각',
  };

  const modes = {
    0: '일반',
    1: '시선',
    2: '청각',
  };

  const statuses = {
    0: '미적용',
    1: '국내',
    2: '국내, 해외',
    3: '해외',
  };

  const processContentData = (data) => {
    return data.map((item) => {
      const ec_number = item.ec_number ? item.ec_number.toString().charAt(0) : '';
      const ec_mode = item.ec_mode !== undefined ? modes[item.ec_mode] : '';
      const ec_status = item.ec_status !== undefined ? statuses[item.ec_status] : '';

      return {
        ...item,
        ec_number: developmentAreas[ec_number] || '알 수 없음',
        ec_mode: ec_mode || '알 수 없음',
        ec_status: ec_status || '알 수 없음',
      };
    });
  };

  const selectContentsList = useCallback(() => {
    console.log('test');
    fetch('https://ser.eyas.o-r.kr/select/contentslist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then(({ status, data }) => {
        if (status) {
          const processedData = processContentData(data);
          setCl(processedData);
        } else {
          alert('데이터를 가져오는 데 실패했습니다.');
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        alert('서버 오류가 발생했습니다.');
      });
  }, []);

  useEffect(() => {
    selectContentsList();
  }, [selectContentsList]);

  const getMaxCustomerLength = () => {
    let maxLength = 0;
    cl.forEach((item) => {
      const customerLength = item.ec_name.length;
      if (customerLength > maxLength) {
        maxLength = customerLength;
      }
    });
    return maxLength;
  };

  const keys = Object.keys(columnTitles);
  const maxCustomerLength = getMaxCustomerLength();
  const customerColumnWidth = maxCustomerLength * 8; // 글자 하나의 예상 폭 (단위는 px)

  const columns = [
    ...keys.map(key => ({
      field: key,
      headerName: columnTitles[key],
      width: key === 'ec_name' ? customerColumnWidth : 150,
      editable: true,
    })),
  ];

  const filteredRows = cl.filter((item) =>
    keys.some((key) => {
      const value = item[key] ? item[key].toString().toLowerCase() : '';
      return value.includes(filterText.toLowerCase());
    })
  ).map((item, index) => ({
    id: index,
    ...item,
  }));

  return (
    <Menu>
      <Box
        sx={{
          float: 'left',
          width: 'calc(100% - 260px)',
          minWidth: '98%',
          height: '85vh',
          marginTop: '30px',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          padding: '10px 2px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Typography sx={{ marginLeft: '30px' }} variant="h4" component="h2" gutterBottom>
            콘텐츠 관리 리스트
          </Typography>
          <input
            type="text"
            placeholder="검색어를 입력하세요"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={{ padding: '5px', width: '30%', height: '40px' }}
          />
        </Box>

        <DataGrid
          rows={filteredRows}
          columns={columns}
          // onRowClick={handleDetailClick}
          style={{ height: '85%', width: '100%' }}
          onProcessRowUpdateError={(error) => {
            console.error('Error updating row:', error);
          }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              fontSize: '1.6rem', // 헤더 글자 크기 조절
            },
            '& .MuiDataGrid-cell': {
              fontSize: '1.5rem', // 셀 글자 크기 조절
            },
            '& .MuiTablePagination-select': {
              display: 'none'
            },
            '& .MuiTablePagination-selectLabel': {
              display: 'none'
            },
            '& .MuiTablePagination-actions': {
              fontSize: '1.8rem',
              marginRight: '15px'
            },
            '& .MuiTablePagination-displayedRows': {
              fontSize: '1.4rem', // 페이지 표시 폰트 크기 조정
            },
          }}
        />

      </Box>
    </Menu>
  );
}

export default ContentsList;
