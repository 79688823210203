import React, { useCallback, useState } from 'react'
import Menu from '../Menu/Menu';
import '../../styles/core.css';
import { TextField, Button, Typography, Box } from '@mui/material';
import dayjs from 'dayjs';

function Newupdateboard() {
  const [version, setVersion] = useState('');
  const [content, setContent] = useState('');
  const today  = dayjs().format('YYYY-MM-DD')

  const handleSubmit = useCallback(() => {
    fetch('https://ser.eyas.o-r.kr/insert/nub', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        version,
        content,
        today
      })
    })
      .then((res) => res.json())
      .then(({ status }) => {
        if (status) {
          alert('작성을 완료하였습니다.')
        } else {
          alert('데이터를 가져오는 데 실패했습니다.');
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        alert('서버 오류가 발생했습니다.');
      });
  }, [version, content, today])

  return (
    <Menu>
      <Box sx={{
          float: 'left',
          width: 'calc(100% - 260px)',
          minWidth: '98%',
          minHeight: '95%',
          marginTop: '30px',
          overflowX: 'auto',
          // whiteSpace: 'nowrap',
          padding: '10px 2px',
        }}>
        <Typography variant="h4" component="h1" gutterBottom>
          글 작성
        </Typography>
        <TextField
          fullWidth
          label="버전"
          // value={title}
          onChange={(e) => { setVersion(e.target.value) }}
          margin="normal"
        />
        <TextField
          fullWidth
          label="내용"
          // value={content}
          onChange={(e) => { setContent(e.target.value) }}
          margin="normal"
          multiline
          rows={10}
        />
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit} >
            제출
          </Button>
        </Box>
      </Box>
    </Menu>
  )
}

export default Newupdateboard
