import React, { useEffect, useState } from 'react';
import Menu from '../Menu/Menu';
import Grid from '@mui/material/Grid';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import './css/cmgraphpage.css';
import { SimpleSouthKoreaMapChart } from "react-simple-south-korea-map-chart";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// 지역 이름과 매핑된 이름
const areaToLocaleMap = {
  "부산": "부산광역시",
  "대구": "대구광역시",
  "대전": "대전광역시",
  "강원": "강원도",
  "광주": "광주광역시",
  "경기": "경기도",
  "인천": "인천광역시",
  "제주": "제주특별자치도",
  "충북": "충청북도",
  "경북": "경상북도",
  "전북": "전라북도",
  "세종": "세종특별자치시",
  "충남": "충청남도",
  "경남": "경상남도",
  "전남": "전라남도",
  "울산": "울산광역시",
  "서울": "서울특별시",
  "경산": "경상북도",
  "경주": "경상북도",
  "구미": "경상북도",
  "김해": "경상남도",
  "동탄": "경기도",
  "수원": "경기도",
  "안동": "경상북도",
  "여수": "전라남도",
  "오산": "경기도",
  "원주": "강원도",
  "철원": "강원도",
  "청송": "경상북도",
  "춘천": "강원도",
  "평택": "강원도",
  "포항": "경상북도",
  "서산": "충청남도",
  // 추가 지역 매핑
};

const countryNameToCode = {
  "한국": "KR",
  "베트남": "VN",
  "말레이시아": "MY",
  "튀르키예": "TR",
  "카자흐스탄": "KZ",
  // 추가 국가와 코드 매핑
};

function Cmgraphpage() {
  const [cm, setCm] = useState([]);
  const [barChartData, setBarChartData] = useState({ labels: [], datasets: [] });

  const getLocaleData = (data) => {
    const localeCount = data.reduce((acc, item) => {
      const locale = areaToLocaleMap[item.cm_area] || item.cm_area;
      if (!acc[locale]) {
        acc[locale] = 0;
      }
      acc[locale] += 1; // 갯수를 셉니다
      return acc;
    }, {});

    return Object.entries(localeCount).map(([locale, count]) => ({ locale, count }));
  };

  const localeData = getLocaleData(cm);

  const setColorByCount = (count) => {
    if (count === 0) return "#E0E0E0";  // 가장 밝은 파란색 (흰색에 가까운)
    if (count > 35) return "#0288D1";  // 가장 진한 파란색
    if (count > 30) return "#039BE5";  // 약간 진한 파란색
    if (count > 25) return "#03A9F4";  // 중간 정도 파란색
    if (count > 20) return "#29B6F6";   // 밝은 파란색
    if (count > 15) return "#4FC3F7";   // 좀 더 밝은 파란색
    if (count > 10) return "#81D4FA";    // 밝은 하늘색
    if (count > 5) return "#B3E5FC";     // 매우 연한 하늘색
    return "#E1F5FE";                    // 가장 연한 파란색
  };

  useEffect(() => {
    fetch('https://ser.eyas.o-r.kr/select/totalCm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then(({ status, data }) => {
        if (status) {
          setCm(data);
        } else {
          alert('데이터를 가져오는 데 실패했습니다.');
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        alert('서버 오류가 발생했습니다.');
      });
  }, []);

  useEffect(() => {
    if (cm.length === 0) return;

    // 막대 그래프 데이터 처리
    const yearCount = cm.reduce((acc, item) => {
      const year = item.cm_year;
      acc[year] = (acc[year] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(yearCount);
    const data = Object.values(yearCount);

    setBarChartData({
      labels: labels,
      datasets: [
        {
          label: '년도별 판매 수량',
          data: data,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        }
      ]
    });
  }, [cm]);

  useEffect(() => {
    if (cm.length === 0) return; // 데이터가 없으면 초기화만

    let map = am4core.create("chartdiv", am4maps.MapChart);
    map.geodata = am4geodata_worldLow;
    map.projection = new am4maps.projections.Miller();

    let polygonSeries = new am4maps.MapPolygonSeries();
    polygonSeries.useGeodata = true;
    polygonSeries.exclude = ["CN", "TW", "AQ"];
    map.series.push(polygonSeries);

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";

    polygonTemplate.adapter.add("fill", function (fill, target) {
      if (target.dataItem && target.dataItem.dataContext) {
        let countryCode = target.dataItem.dataContext.id;
        let countryName = target.dataItem.dataContext.name;

        const isOverseas = cm.some(item =>
          item.cm_contract === '해외' && countryCode === countryNameToCode[item.cm_area]
        );

        if (isOverseas) {
          return am4core.color("#1E78FA");
        } else {
          return countryCode === 'KR' ? am4core.color("#1E78FA") : am4core.color("#E0E0E0");
        }
      }
      return fill;
    });

    // let hs = polygonTemplate.states.create("hover");
    // hs.adapter.add("fill", function (fill, target) {
    //   if (target.dataItem && target.dataItem.dataContext) {
    //     let countryCode = target.dataItem.dataContext.id;
    //     if (target.fill.toString() === am4core.color("#1E78FA").toString()) {
    //       return am4core.color("#FF0000");
    //     }
    //     return am4core.color("#CCCCCC");
    //   }
    //   return fill;
    // });

    return () => {
      map.dispose();
    };
  }, [cm]);

  return (
    <Menu>
      <Grid container spacing={3} sx={{ height: '100%', marginTop: '30px' }}>
        <Grid item xs={12} sm={12} sx={{ height: '50vh' }}>
          <div id='chartdiv' style={{ width: '100%', height: '100%' }} />
        </Grid>
        <Grid item xs={12} sm={3} sx={{ height: '100%', marginTop: '-60px', marginLeft: '10px' }}>
          <SimpleSouthKoreaMapChart
            setColorByCount={setColorByCount}
            data={localeData} // 데이터 전달
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ marginTop: '5px', marginLeft: '30px' }}>
          <Bar
            data={barChartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: '년도별 판매 수량'
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </Menu>
  );
}

export default Cmgraphpage;
