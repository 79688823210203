import React from 'react';
import Logo from '../../assets/images/logo/eyas_ft_logo.png';
import '../../styles/core.css';
import './css/menu.css';
import { FaQuestionCircle, FaDatabase, FaArrowLeft } from "react-icons/fa";
import { RiMovie2Fill } from "react-icons/ri";
import { IoCallSharp, IoNotificationsSharp } from "react-icons/io5";
import { SlMagicWand } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';

function Menu({ children }) {
  const navigate = useNavigate();

  return (
    <div className='container'>
      <div className='sub_top'>
        <button className="main_back" onClick={() => navigate("/")}>
          <FaArrowLeft className='mr10' />
          메인
        </button>
      </div>

      <section className='sub_menu_wrap'>
        <ul className='sub_menu'>
          <li id='li_bbs_01'>
            <button type='button' onClick={() => navigate("/cmp")}>
              <IoNotificationsSharp className='mr10' />고객 관리
            </button>
          </li>
          <li id='li_bbs_02'>
            <button type='button' onClick={() => navigate("/cmap")}>
              <SlMagicWand className='mr10' />신규 추가
            </button>
          </li>
          <li id='li_bbs_03'>
            <button type='button' onClick={() => navigate("/updb")}>
              <IoCallSharp className='mr10' />업데이트 목록
            </button>
          </li>
          <li id='li_bbs_04'>
            <button type='button' onClick={() => navigate("/ssn")}>
              <RiMovie2Fill className='mr10' />시리얼 관리
            </button>
          </li>
          <li id='li_bbs_05'>
            <button type='button' onClick={() => navigate("/spd")}>
              <FaDatabase className='mr10' />플레이 데이터
            </button>
          </li>
          <li id='li_bbs_06'>
            <button type='button' onClick={() => navigate("/cl")}>
              <FaQuestionCircle className='mr10' />콘텐츠 목록
            </button>
          </li>
        </ul>
        <h1 className="logo">
          <button type="button" onClick={() => navigate("/")}>
            <img src={Logo} alt="로고" />
          </button>
        </h1>
      </section>
      <div className="content">
        {children}
      </div>
    </div>
  );
}

export default Menu;
