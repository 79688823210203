import React, { useCallback, useState } from 'react'
import Menu from '../Menu/Menu';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    width: '500px',
    height: '50px', // TextField 높이 조정
  },
  '& .MuiOutlinedInput-input': {
    display: 'flex', // 수직 중앙 정렬을 위한 flexbox 사용
    alignItems: 'center', // 수직 중앙 정렬 설정
    padding: '15px', // TextField 내부 패딩 조정
    fontSize: '1.2rem', // defaultValue 글자 크기 조정
    textAlign: 'left', // defaultValue 왼쪽 정렬
  },
  '& .MuiFormLabel-root': {
    display: 'flex', // 수직 중앙 정렬을 위한 flexbox 사용
    alignItems: 'center', // 수직 중앙 정렬 설정
    // padding: '15px', // TextField 내부 패딩 조정
    fontSize: '1.2rem', // defaultValue 글자 크기 조정
    textAlign: 'left', // defaultValue 왼쪽 정렬
  },
}));

function Additionserialnumber() {
  // const [countryCode, setCountryCode] = useState('')
  const [serialCount, setSerialCount] = useState(0)

  const location = useLocation();
  const { tableName } = location.state || {}
  const countryCode = tableName.toUpperCase()

  const navigate = useNavigate()

  const createSerial = useCallback(() => {
    if (countryCode.length === 3 && serialCount !== 0) {
      fetch('https://ser.eyas.o-r.kr/addition/serialnumber', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          countryCode,
          serialCount
        })
      })
        .then((res) => res.json())
        .then(({ status }) => {
          if (status) {
            alert('시리얼 넘버 생성 완료')
            navigate('/ssn')
          } else {
            alert('데이터를 가져오는 데 실패했습니다.');
          }
        })
        .catch((err) => {
          console.error('Error fetching data:', err);
          alert('서버 오류가 발생했습니다.');
        });
    } else {
      alert('국가코드를 3자리로 지정해주시거나, 시리얼 생성 갯수에 숫자를 입력해주세요.')
    }
  }, [countryCode, serialCount])


  return (
    <Menu>
      <Box sx={{
        float: 'left',
        width: 'calc(100% - 260px)',
        minWidth: '98%',
        marginTop: '30px',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        padding: '10px 2px',
      }}>

        <CustomTextField
          id="unusual_textField"
          fullWidth
          label="시리얼 생성 갯수"
          variant="outlined"
          helperText="시리얼 생성 갯수를 입력해주세요."
          style={{ marginTop: '20px' }}
          onChange={(e) => { setSerialCount(Number(e.target.value) || 0) }} />

        <Button
          variant="contained"
          color="primary"
          onClick={() => createSerial()}
          style={{ marginTop: '20px', float: 'left' }}
        >
          글 쓰기
        </Button>
      </Box>
    </Menu>
  )
}

export default Additionserialnumber
