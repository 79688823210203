// import React, { useCallback, useState } from 'react'
// import Menu from '../Menu/Menu';
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import Button from '@mui/material/Button';

// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { styled } from '@mui/material/styles';
// import dayjs from 'dayjs';

// import './css/cmaddpage.css'


// const status_choice = [
//   {
//     value: '유지',
//     label: '유지',
//   },
//   {
//     value: '만료',
//     label: '만료',
//   },
// ];

// const account_choice = [
//   {
//     value: 1,
//     label: '1',
//   },
//   {
//     value: 0,
//     label: '0',
//   },
// ];

// const contract_choice = [
//   {
//     value: '일시불',
//     label: '일시불',
//   },
//   {
//     value: '렌탈',
//     label: '렌탈',
//   },
//   {
//     value: '해외',
//     label: '해외',
//   },
// ];

// const division_choice = [
//   {
//     value: '개인고객',
//     label: '개인고객',
//   },
//   {
//     value: '대학교',
//     label: '대학교',
//   },
//   {
//     value: '대학병원',
//     label: '대학병원',
//   },
//   {
//     value: '병원',
//     label: '병원',
//   },
//   {
//     value: '보청기',
//     label: '보청기',
//   },
//   {
//     value: '복지센터',
//     label: '복지센터',
//   },
//   {
//     value: '시설',
//     label: '시설',
//   },
//   {
//     value: '아동발달',
//     label: '아동발달',
//   },
//   {
//     value: '요양병원',
//     label: '요양병원',
//   },
//   {
//     value: '요양원',
//     label: '요양원',
//   },
//   {
//     value: '재활병원',
//     label: '재활병원',
//   },
//   {
//     value: '주간보호',
//     label: '주간보호',
//   },
//   {
//     value: '초등학교',
//     label: '초등학교',
//   },
//   {
//     value: '치매센터',
//     label: '치매센터',
//   },
//   {
//     value: '특수학교',
//     label: '특수학교',
//   },
//   {
//     value: '해외',
//     label: '해외',
//   },
// ];

// const servicePeriod_choice = [
//   {
//     value: 0,
//     label: '0',
//   },
//   {
//     value: 12,
//     label: '12',
//   },
//   {
//     value: 24,
//     label: '24',
//   },
// ];

// const model_choice = [
//   {
//     value: 'W',
//     label: 'W',
//   },
//   {
//     value: 'A',
//     label: 'A',
//   },
//   {
//     value: 'W+A',
//     label: 'W+A',
//   },
// ];

// const CustomSelectTextField = styled(TextField)(({ theme }) => ({
//   '& .MuiInputBase-root': {
//     width: '500px',
//     height: '50px', // TextField 높이 조정
//   },
//   '& .MuiOutlinedInput-input': {
//     display: 'flex', // 수직 중앙 정렬을 위한 flexbox 사용
//     alignItems: 'center', // 수직 중앙 정렬 설정
//     padding: '15px', // TextField 내부 패딩 조정
//     fontSize: '1.2rem', // defaultValue 글자 크기 조정
//     textAlign: 'left', // defaultValue 왼쪽 정렬
//   },
//   '& .MuiFormLabel-root': {
//     fontSize: '1.5rem', // Label 텍스트 크기 변경
//     transform: 'translate(10px, -8px) scale(0.75)', // 라벨 위치 조정
//   },
//   '& .MuiFormLabel-root.Mui-focused': {
//     transform: 'translate(8px, -20px) scale(1)', // 라벨 위치 조정 (포커스 상태)
//   },
//   '& .MuiFormHelperText-root': {
//     color: 'black', // HelperText 텍스트 색상 변경
//     fontSize: '1rem', // HelperText 텍스트 크기 변경
//   },
// }));

// const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
//   minHeight: '150px', // MenuItem 높이 조정
// }));

// const CustomTextField = styled(TextField)(({ theme }) => ({
//   '& .MuiInputBase-root': {
//     width: '500px',
//     height: '50px', // TextField 높이 조정
//   },
//   '& .MuiOutlinedInput-input': {
//     display: 'flex', // 수직 중앙 정렬을 위한 flexbox 사용
//     alignItems: 'center', // 수직 중앙 정렬 설정
//     padding: '15px', // TextField 내부 패딩 조정
//     fontSize: '1.2rem', // defaultValue 글자 크기 조정
//     textAlign: 'left', // defaultValue 왼쪽 정렬
//   },
//   '& .MuiFormLabel-root': {
//     display: 'flex', // 수직 중앙 정렬을 위한 flexbox 사용
//     alignItems: 'center', // 수직 중앙 정렬 설정
//     // padding: '15px', // TextField 내부 패딩 조정
//     fontSize: '1.2rem', // defaultValue 글자 크기 조정
//     textAlign: 'left', // defaultValue 왼쪽 정렬
//   },
// }));

// const CustomDemoContainer = styled(DemoContainer)({

//   padding: '20px',
//   backgroundColor: '#f9f9f9',
//   borderRadius: '10px',
//   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//   '& .MuiFormControl-root': {
//     marginBottom: '20px',
//   },
// });

// const CustomDatePicker = styled(DatePicker)({
//   '& .MuiOutlinedInput-root': {
//     width: '100%',
//     height: '50px',
//     '& .MuiOutlinedInput-input': {
//       padding: '15px',
//     },
//   },
//   '& .MuiInputLabel-root': {
//     fontSize: '1.5rem',
//     transform: 'translate(14px, 12px) scale(1)',
//   },
//   '& .MuiInputLabel-root.Mui-focused': {
//     transform: 'translate(14px, -6px) scale(0.75)',
//   },
// });


// function CMAddPage() {
//   const [statusValue, setStatusValue] = useState('유지');
//   const [accountValue, setAccountValue] = useState(1);
//   const [contractValue, setContractValue] = useState('일시불');
//   const [unusualValue, setUnusualValue] = useState(null);
//   const [customerValue, setCustomerValue] = useState(null);
//   const [divisionValue, setDivisionValue] = useState('아동발달');
//   const [areaValue, setAreaValue] = useState(null);
//   const [installDateValue, setInstallDateValue] = useState(dayjs().format('YYYY-MM-DD'));
//   const [servicePeriodValue, setServicePeriodValue] = useState(24);
//   const [modelValue, setModelValue] = useState('W');
//   const [quantityValue, setQuantityValue] = useState(null);
//   const [assignee1Value, setAssignee1Value] = useState(null);
//   const [assigneePhone1Value, setAssigneePhone1Value] = useState(null);
//   const [assignee2Value, setAssignee2Value] = useState(null);
//   const [assigneePhone2Value, setAssigneePhone2Value] = useState(null);
//   const [otherValue, setOtherValue] = useState(null);



//   const buttonClickSendData = useCallback(() => {
//     const yearValue = installDateValue.year(); // 연도 추출
//     let selectServicePeriod;

//     if (servicePeriodValue === 24) {
//       selectServicePeriod = 2
//     } else if (servicePeriodValue === 12) {
//       selectServicePeriod = 1
//     } else {
//       selectServicePeriod = 0
//     }

//     const endDateValue = installDateValue.add(selectServicePeriod, 'year'); // 2년 더한 날짜

//     const formattedInstallDate  = installDateValue.format('YYYY-MM-DD')
//     const formattedEndDate  = endDateValue.format('YYYY-MM-DD')
//     console.log(selectServicePeriod)
//     console.log(yearValue)
//     console.log(endDateValue)



//     // 서버로 데이터 전송
//     fetch('https://ser.eyas.o-r.kr/insert/totalCm', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         yearValue,
//         statusValue,
//         accountValue,
//         contractValue,
//         unusualValue,
//         customerValue,
//         divisionValue,
//         areaValue,
//         formattedInstallDate,
//         formattedEndDate,
//         servicePeriodValue,
//         modelValue,
//         quantityValue,
//         assignee1Value,
//         assigneePhone1Value,
//         assignee2Value,
//         assigneePhone2Value,
//         otherValue
//       }), // 선택한 값을 JSON 형식으로 전송
//     })
//     .then(({ status, data }) => {
//       if (status) {
//         alert('정상작동')
//       } else {
//         alert('데이터를 가져오는 데 실패했습니다.');
//       }
//     })
//     .catch((err) => {
//       console.error('Error fetching data:', err);
//       alert('서버 오류가 발생했습니다.');
//     });
// }, [
//     statusValue,
//     accountValue,
//     contractValue,
//     unusualValue,
//     customerValue,
//     divisionValue,
//     areaValue,
//     installDateValue,
//     servicePeriodValue,
//     modelValue,
//     quantityValue,
//     assignee1Value,
//     assigneePhone1Value,
//     assignee2Value,
//     assigneePhone2Value,
//     otherValue]);

//   const SendDataButton = () => {
//     return (
//       <button  style={{ marginLeft: '300px', border: '1px' }} onClick={buttonClickSendData}>Send Data</button>
//     );
//   };


//   return (
//     <Menu>
//       <Box
//         component="form"
//         sx={{
//           '& > :not(style)': { m: 1, width: '250px', maxWidth: '100%' },
//         }}
//         noValidate
//         autoComplete="off"
//         marginTop={'30px'}
//       >

//         <CustomSelectTextField
//           id="status_textField"
//           select
//           label="상태"
//           defaultValue="유지"
//           helperText="계약 상태를 골라주세요."
//           size='small'
//           onChange={(e) => { setStatusValue(e.target.value) }}
//         >
//           {status_choice.map((option) => (
//             <CustomMenuItem key={option.value} value={option.value} >
//               {option.label}
//             </CustomMenuItem>
//           ))}
//         </CustomSelectTextField>



//         <CustomSelectTextField
//           id="account_textField"
//           select
//           style={{ marginLeft: '300px' }}
//           label="계정"
//           defaultValue="1"
//           helperText="Please select your currency"
//           size="small"
//           onChange={(e) => { setAccountValue(e.target.value) }}
//         >
//           {account_choice.map((option) => (
//             <MenuItem key={option.value} value={option.value}>
//               {option.label}
//             </MenuItem>
//           ))}
//         </CustomSelectTextField>

//         <br />

//         <CustomSelectTextField
//           id="contract_textField"
//           select
//           label="계약형태"
//           defaultValue="일시불"
//           helperText="Please select your currency"
//           size="small"
//           onChange={(e) => { setContractValue(e.target.value) }}
//         >
//           {contract_choice.map((option) => (
//             <MenuItem key={option.value} value={option.value}>
//               {option.label}
//             </MenuItem>
//           ))}
//         </CustomSelectTextField>



//         <CustomTextField
//           id="unusual_textField"
//           fullWidth
//           label="특이사항"
//           variant="outlined"
//           helperText="Please select your currency"
//           style={{ marginLeft: '300px' }}
//           onChange={(e) => { setUnusualValue(e.target.value) }} />

//         <br />

//         <CustomTextField
//           id="customer_textField"
//           label="고객사"
//           variant="outlined"
//           helperText="Please select your currency"
//           onChange={(e) => { setCustomerValue(e.target.value) }} />



//         <CustomSelectTextField
//           id="division_textField"
//           select
//           label="구분"
//           defaultValue="아동발달"
//           helperText="Please select your currency"
//           style={{ marginLeft: '300px' }}
//           onChange={(e) => { setDivisionValue(e.target.value) }}
//         >
//           {division_choice.map((option) => (
//             <MenuItem key={option.value} value={option.value}>
//               {option.label}
//             </MenuItem>
//           ))}
//         </CustomSelectTextField>

//         <br />

//         <CustomTextField
//           id="area_textField"
//           label="지역"
//           variant="outlined"
//           helperText="Please select your currency"
//           onChange={(e) => { setAreaValue(e.target.value) }} />



//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//           <CustomDemoContainer components={['DatePicker']}>
//             <DemoItem label="설치일자">
//               <CustomDatePicker
//                 onChange={(newValue) => {
//                   setInstallDateValue(newValue)
//                   console.log(installDateValue)
//                 }} />
//             </DemoItem>
//           </CustomDemoContainer>
//         </LocalizationProvider>

//         <br />

//         <CustomSelectTextField
//           id="servicePeriod_textField"
//           select
//           label="무상기간"
//           defaultValue="24"
//           helperText="Please select your currency"
//           onChange={(e) => { setServicePeriodValue(e.target.value) }}
//         >
//           {servicePeriod_choice.map((option) => (
//             <MenuItem key={option.value} value={option.value}>
//               {option.label}
//             </MenuItem>
//           ))}
//         </CustomSelectTextField>



//         <CustomSelectTextField
//           id="model_textField"
//           select
//           label="Model"
//           defaultValue="W"
//           helperText="Please select your currency"
//           style={{ marginLeft: '300px' }}
//           onChange={(e) => { setModelValue(e.target.value) }}
//         >
//           {model_choice.map((option) => (
//             <MenuItem key={option.value} value={option.value}>
//               {option.label}
//             </MenuItem>
//           ))}
//         </CustomSelectTextField>

//         <br />

//         <CustomTextField
//           id="quantity_textField"
//           label="계약대수"
//           variant="outlined"
//           helperText="Please select your currency"
//           onChange={(e) => { setQuantityValue(e.target.value) }} />

//         <br />

//         <CustomTextField
//           id="assignee1_textField"
//           label="담당1"
//           variant="outlined"
//           helperText="Please select your currency"
//           onChange={(e) => { setAssignee1Value(e.target.value) }} />



//         <CustomTextField
//           id="assigneePhone1_textField"
//           label="담당1 연락처"
//           variant="outlined"
//           helperText="Please select your currency"
//           style={{ marginLeft: '300px' }}
//           onChange={(e) => { setAssigneePhone1Value(e.target.value) }} />

//         <br />

//         <CustomTextField
//           id="assignee2_textFieldc"
//           label="담당2"
//           variant="outlined"
//           helperText="Please select your currency"
//           onChange={(e) => { setAssignee2Value(e.target.value) }} />



//         <CustomTextField
//           id="assigneePhone2_textField"
//           label="담당2 연락처"
//           variant="outlined"
//           helperText="Please select your currency"
//           style={{ marginLeft: '300px' }}
//           onChange={(e) => { setAssigneePhone2Value(e.target.value) }} />

//         <br />

//         <CustomTextField
//           id="other_textField"
//           label="비고"
//           variant="outlined"
//           helperText="Please select your currency"
//           onChange={(e) => { setOtherValue(e.target.value) }} />

//         <Button
//         variant="contained"
//         color="primary"
//         style={{ marginLeft: '300px', border: '1px' }}
//         onClick={buttonClickSendData}
//         >
//           Send Data
//         </Button>

//       </Box>
//     </Menu>
//   )
// }

// export default CMAddPage


import React, { useCallback, useState } from 'react'
import Menu from '../Menu/Menu';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';

import './css/cmaddpage.css'

const status_choice = [
  {
    value: '유지',
    label: '유지',
  },
  {
    value: '만료',
    label: '만료',
  },
];

const account_choice = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 0,
    label: '0',
  },
];

const contract_choice = [
  {
    value: '일시불',
    label: '일시불',
  },
  {
    value: '렌탈',
    label: '렌탈',
  },
  {
    value: '해외',
    label: '해외',
  },
];

const division_choice = [
  {
    value: '개인고객',
    label: '개인고객',
  },
  {
    value: '대학교',
    label: '대학교',
  },
  {
    value: '대학병원',
    label: '대학병원',
  },
  {
    value: '병원',
    label: '병원',
  },
  {
    value: '보청기',
    label: '보청기',
  },
  {
    value: '복지센터',
    label: '복지센터',
  },
  {
    value: '시설',
    label: '시설',
  },
  {
    value: '아동발달',
    label: '아동발달',
  },
  {
    value: '요양병원',
    label: '요양병원',
  },
  {
    value: '요양원',
    label: '요양원',
  },
  {
    value: '재활병원',
    label: '재활병원',
  },
  {
    value: '주간보호',
    label: '주간보호',
  },
  {
    value: '초등학교',
    label: '초등학교',
  },
  {
    value: '치매센터',
    label: '치매센터',
  },
  {
    value: '특수학교',
    label: '특수학교',
  },
  {
    value: '해외',
    label: '해외',
  },
];

const servicePeriod_choice = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 24,
    label: '24',
  },
];

const model_choice = [
  {
    value: 'W',
    label: 'W',
  },
  {
    value: 'A',
    label: 'A',
  },
  {
    value: 'W+A',
    label: 'W+A',
  },
];

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    width: '100%',
    height: '50px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '15px',
    fontSize: '1.2rem',
    textAlign: 'left',
  },
  '& .MuiFormLabel-root': {
    fontSize: '1.4rem',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '2rem', // 원하는 폰트 사이즈로 조정
  },
}));

const CustomSelectTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    width: '100%',
    height: '50px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '15px',
    fontSize: '1.3rem',
    textAlign: 'left',
  },
  '& .MuiFormLabel-root': {
    fontSize: '1.3rem',
    textAlign: 'left',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '2rem', // 원하는 폰트 사이즈로 조정
  },
}));

const CustomDatePicker = styled(DatePicker)({
  '& .MuiOutlinedInput-root': {
    width: '100%',
    height: '50px',
    '& .MuiOutlinedInput-input': {
      padding: '15px',
    },
  },
  '& .MuiInputLabel-root': {
    fontSize: '1.5rem',
  },
});

function CMAddPage() {
  const [statusValue, setStatusValue] = useState('유지');
  const [accountValue, setAccountValue] = useState(1);
  const [contractValue, setContractValue] = useState('일시불');
  const [unusualValue, setUnusualValue] = useState(null);
  const [customerValue, setCustomerValue] = useState('');
  const [divisionValue, setDivisionValue] = useState('아동발달');
  const [areaValue, setAreaValue] = useState(null);
  const [installDateValue, setInstallDateValue] = useState(() => dayjs().format('YYYY-MM-DD'));
  const [servicePeriodValue, setServicePeriodValue] = useState(24);
  const [modelValue, setModelValue] = useState('W');
  const [quantityValue, setQuantityValue] = useState(null);
  const [assignee1Value, setAssignee1Value] = useState(null);
  const [assigneePhone1Value, setAssigneePhone1Value] = useState(null);
  const [assignee2Value, setAssignee2Value] = useState(null);
  const [assigneePhone2Value, setAssigneePhone2Value] = useState(null);
  const [otherValue, setOtherValue] = useState(null);

  const buttonClickSendData = useCallback(() => {
    const yearValue = installDateValue.year(); // 연도 추출
    let selectServicePeriod;

    if (servicePeriodValue === 24) {
      selectServicePeriod = 2;
    } else if (servicePeriodValue === 12) {
      selectServicePeriod = 1;
    } else {
      selectServicePeriod = 0;
    }

    const endDateValue = installDateValue.add(selectServicePeriod, 'year'); // 2년 더한 날짜
    const formattedInstallDate = installDateValue.format('YYYY-MM-DD');
    const formattedEndDate = endDateValue.format('YYYY-MM-DD');

    // 서버로 데이터 전송
    fetch('https://ser.eyas.o-r.kr/insert/totalCm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        yearValue,
        statusValue,
        accountValue,
        contractValue,
        unusualValue,
        customerValue,
        divisionValue,
        areaValue,
        formattedInstallDate,
        formattedEndDate,
        servicePeriodValue,
        modelValue,
        quantityValue,
        assignee1Value,
        assigneePhone1Value,
        assignee2Value,
        assigneePhone2Value,
        otherValue
      }), // 선택한 값을 JSON 형식으로 전송
    })
      .then(({ status }) => {
        if (status) {
          alert('정상작동');
        } else {
          alert('데이터를 가져오는 데 실패했습니다.');
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        alert('서버 오류가 발생했습니다.');
      });
  }, [
    statusValue,
    accountValue,
    contractValue,
    unusualValue,
    customerValue,
    divisionValue,
    areaValue,
    installDateValue,
    servicePeriodValue,
    modelValue,
    quantityValue,
    assignee1Value,
    assigneePhone1Value,
    assignee2Value,
    assigneePhone2Value,
    otherValue
  ]);

  return (
    <Menu>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '100%' },
          padding: 2,
        }}
        noValidate
        autoComplete="off"
        marginTop={'30px'}
        marginRight={'30px'}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <CustomSelectTextField
              id="status_textField"
              select
              label="상태"
              value={statusValue}
              helperText="계약 상태를 골라주세요."
              size='small'
              onChange={(e) => { setStatusValue(e.target.value) }}
            >
              {status_choice.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomSelectTextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelectTextField
              id="account_textField"
              select
              label="계정"
              value={accountValue}
              helperText="계정을 선택하세요."
              size="small"
              onChange={(e) => { setAccountValue(e.target.value) }}
            >
              {account_choice.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomSelectTextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelectTextField
              id="contract_textField"
              select
              label="계약형태"
              value={contractValue}
              helperText="계약 형태를 선택하세요."
              size="small"
              onChange={(e) => { setContractValue(e.target.value) }}
            >
              {contract_choice.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomSelectTextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTextField
              id="unusual_textField"
              label="특이사항"
              variant="outlined"
              value={unusualValue || ''}
              helperText="특이사항을 입력하세요."
              onChange={(e) => { setUnusualValue(e.target.value) }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTextField
              id="customer_textField"
              label="고객사"
              variant="outlined"
              value={customerValue || ''}
              helperText="고객사 이름을 입력하세요."
              onChange={(e) => { setCustomerValue(e.target.value) }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelectTextField
              id="division_textField"
              select
              label="구분"
              value={divisionValue}
              helperText="구분을 선택하세요."
              onChange={(e) => { setDivisionValue(e.target.value) }}
            >
              {division_choice.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomSelectTextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTextField
              id="area_textField"
              label="지역"
              variant="outlined"
              value={areaValue || ''}
              helperText="지역을 입력하세요."
              onChange={(e) => { setAreaValue(e.target.value) }} />
          </Grid>

          <Grid item xs={12}  sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                onChange={(newValue) => {
                  setInstallDateValue(newValue);
                }}
                renderInput={(params) => <CustomTextField {...params} label="설치일자" />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelectTextField
              id="servicePeriod_textField"
              select
              label="무상기간"
              value={servicePeriodValue}
              helperText="무상기간을 선택하세요."
              onChange={(e) => { setServicePeriodValue(e.target.value) }}
            >
              {servicePeriod_choice.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomSelectTextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelectTextField
              id="model_textField"
              select
              label="Model"
              value={modelValue}
              helperText="모델을 선택하세요."
              onChange={(e) => { setModelValue(e.target.value) }}
            >
              {model_choice.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomSelectTextField>
          </Grid>

          <Grid item xs={12}>
            <CustomTextField
              id="quantity_textField"
              label="계약대수"
              variant="outlined"
              value={quantityValue || ''}
              helperText="계약 대수를 입력하세요."
              onChange={(e) => { setQuantityValue(e.target.value) }} />
          </Grid>
   
          <Grid item xs={12} sm={6}>
            <CustomTextField
              id="assignee1_textField"
              label="담당1"
              variant="outlined"
              value={assignee1Value || ''}
              helperText="담당자1 이름을 입력하세요."
              onChange={(e) => { setAssignee1Value(e.target.value) }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTextField
              id="assigneePhone1_textField"
              label="담당1 연락처"
              variant="outlined"
              value={assigneePhone1Value || ''}
              helperText="담당자1 연락처를 입력하세요."
              onChange={(e) => { setAssigneePhone1Value(e.target.value) }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTextField
              id="assignee2_textField"
              label="담당2"
              variant="outlined"
              value={assignee2Value || ''}
              helperText="담당자2 이름을 입력하세요."
              onChange={(e) => { setAssignee2Value(e.target.value) }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTextField
              id="assigneePhone2_textField"
              label="담당2 연락처"
              variant="outlined"
              value={assigneePhone2Value || ''}
              helperText="담당자2 연락처를 입력하세요."
              onChange={(e) => { setAssigneePhone2Value(e.target.value) }} />
          </Grid>

          <Grid item xs={12}>
            <CustomTextField
              id="other_textField"
              label="비고"
              variant="outlined"
              value={otherValue || ''}
              helperText="비고를 입력하세요."
              onChange={(e) => { setOtherValue(e.target.value) }} />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={buttonClickSendData}
              style={{ width: '100px', float: 'right', fontSize: '1.5rem', marginBottom: '30px' }}
            >
              저장
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Menu>
  )
}

export default CMAddPage;
