import usePagination from '@mui/material/usePagination/usePagination';
import Menu from '../Menu/Menu';
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Paper, Button
} from '@mui/material';
import Box from '@mui/material/Box';

function CmDetailPage() {
  const { paramsId } = useParams();
  const [cm, setCm] = useState({})
  const id = parseInt(paramsId, 10);
  const navigate = useNavigate()

  const selectDetailCm = useCallback(() => {
    console.log(id)
    fetch('https://ser.eyas.o-r.kr/select/detailCm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id
      })
    })
      .then((res) => res.json())
      .then(({ status, data }) => {
        if (status) {
          setCm(data[0] || {});
          console.log(data[0])
        } else {
          alert('데이터를 가져오는 데 실패했습니다.');
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        alert('서버 오류가 발생했습니다.');
      });
  }, [id]);

  useEffect(() => {
    selectDetailCm()
  }, [selectDetailCm])

  const handleChange = (field, value) => {
    setCm((prevCm) => ({
      ...prevCm,
      [field]: value,
    }));
  };

  const handleSave = () => {
    fetch('https://ser.eyas.o-r.kr/update/detailCm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        cm
      ),
    })
      .then((res) => res.json())
      .then(({ status }) => {
        if (status) {
          alert('데이터가 성공적으로 저장되었습니다.');
          navigate('/cmp')
        } else {
          alert('데이터 저장에 실패했습니다.');
        }
      })
      .catch((err) => {
        console.error('Error saving data:', err);
        alert('서버 오류가 발생했습니다.');
      });
  };


  return (
    <Menu>
      <TableContainer component={Paper} sx={{ marginRight: '15px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '20%', fontSize: '1.2rem' }}>Column</TableCell>
              <TableCell sx={{ fontSize: '1.2rem' }}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(cm).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell sx={{ fontSize: '1.2rem' }}>{key}</TableCell>
                <TableCell>
                  <TextField
                    value={value || ''}
                    onChange={(e) => handleChange(key, e.target.value)}
                    fullWidth
                    InputProps={{
                      style: { fontSize: '1.2rem' },  // TextField 내부 글자 크기 조정
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{float: 'right', marginRight: '15px', marginTop: '20px', marginBottom: '30px'}}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/cmp')}
          style={{ fontSize: '1.1rem'}}>
          목록
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          style={{ marginLeft: '15px', fontSize: '1.1rem' }}>
          저장
        </Button>
      </Box>
    </Menu>
  )
}

export default CmDetailPage
