// src/App.js
import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate  } from 'react-router-dom';
import Login from './components/Login/Login';
import Cmpage from './components/Cmpage/Cmpage';
import CMAddPage from './components/CMAddPage/CMAddPage';
import CmDetailPage from './components/CmDetailPage/CmDetailPage';
import Cmgraphpage from './components/CmGraphPage/Cmgraphpage';
// import Menu from './components/Menu/Menu'
import Updateboard from './components/Updateboard/Updateboard';
import Newupdateboard from './components/Newupateboard/Newupdateboard'
import Detailupdateboard from './components/Detailupdateboard/Detailupdateboard';
import Selectserialnumber from './components/Selectserialnumber/Selectserialnumber';
import Detailserialnumber from './components/Detailserialnumber/Detailserialnumber';
import Createserialnumber from './components/Createserialnumber/Createserialnumber';
import Additionserialnumber from './components/Additionserialnumber/Additionserialnumber';
import Selectplaydata from './components/Selectplaydata/Selectplaydata';
import ContentsList from './components/ContentsList/ContentsList';
// import Test from './components/Test/Test'

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
   
    <BrowserRouter>
      <Routes>
        <Route path="/Login" element={<Login setIsAuthenticated={setIsAuthenticated}/>} />
        <Route
          path="/cmp"
          element={isAuthenticated ? <Cmpage /> : <Navigate to="/login" />}
        />
        <Route
          path="/cmap"
          element={isAuthenticated ? <CMAddPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/cmdp/:paramsId"
          element={isAuthenticated ? <CmDetailPage /> : <Navigate to="/login" />}
        />
        <Route path='/' element={<Cmgraphpage/>}/>
        <Route
          path="/updb"
          element={isAuthenticated ? <Updateboard /> : <Navigate to="/login" />}
        />
        <Route
          path="/nub"
          element={isAuthenticated ? <Newupdateboard /> : <Navigate to="/login" />}
        />
        <Route
          path="/dub/:paramsId"
          element={isAuthenticated ? <Detailupdateboard /> : <Navigate to="/login" />}
        />
        <Route
          path="/ssn"
          element={isAuthenticated ? <Selectserialnumber /> : <Navigate to="/login" />}
        />
        <Route
          path="/dsn/:paramsTable"
          element={isAuthenticated ? <Detailserialnumber /> : <Navigate to="/login" />}
        />
        <Route
          path="/csn"
          element={isAuthenticated ? <Createserialnumber /> : <Navigate to="/login" />}
        />
        <Route
          path="/asn"
          element={isAuthenticated ? <Additionserialnumber /> : <Navigate to="/login" />}
        />
        <Route
          path="/spd"
          element={isAuthenticated ? <Selectplaydata /> : <Navigate to="/login" />}
        />
        <Route
          path="/cl"
          element={isAuthenticated ? <ContentsList /> : <Navigate to="/login" />}
        />
      </Routes>
    </BrowserRouter>
    
  );
}

export default App;
