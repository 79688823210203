import React, { useCallback, useEffect, useState } from 'react';
import Menu from '../Menu/Menu';
import '../../styles/core.css';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const columnTitles = {
  cm_year: '구분',
  cm_status: '상태',
  cm_customer: '고객사',
  cm_division: '구분',
  cm_area: '지역',
  cm_installDate: '설치일자',
  cm_endDate: '만기일자',
  cm_assignee1: '담당1',
  cm_assigneePhone1: '담당1 연락처',
  cm_updateDate: 'Update 최종일',
};

function Cmpage() {
  const [cm, setCm] = useState([]);
  const [filterText, setFilterText] = useState('');

  const navigate = useNavigate();

  const selectTotalCm = useCallback(() => {
    fetch('https://ser.eyas.o-r.kr/select/totalCm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then(({ status, data }) => {
        if (status) {
          setCm(data);
        } else {
          alert('데이터를 가져오는 데 실패했습니다.');
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        alert('서버 오류가 발생했습니다.');
      });
  }, []);

  useEffect(() => {
    selectTotalCm();
  }, [selectTotalCm]);

  const getMaxCustomerLength = () => {
    let maxLength = 0;
    cm.forEach((item) => {
      const customerLength = item.cm_customer.length;
      if (customerLength > maxLength) {
        maxLength = customerLength;
      }
    });
    return maxLength;
  };

  const keys = Object.keys(columnTitles);
  const maxCustomerLength = getMaxCustomerLength();
  const customerColumnWidth = maxCustomerLength * 8; // 글자 하나의 예상 폭 (단위는 px)

  const columns = [
    ...keys.map(key => ({
      field: key,
      headerName: columnTitles[key],
      width: key === 'cm_customer' ? customerColumnWidth : 150,
      editable: true,
    })),
  ];

  const handleDetailClick = (params) => {
    const paramsId = params.row.cm_Index
    navigate(`/cmdp/${paramsId}`);
  };


  const filteredRows = cm.filter((item) =>
    keys.some((key) => {
      const value = item[key] ? item[key].toString().toLowerCase() : '';
      return value.includes(filterText.toLowerCase());
    })
  ).map((item, index) => ({
    id: index,
    ...item,
  }));

  return (
    <Menu>
      <Box
        sx={{
          float: 'left',
          width: 'calc(100% - 260px)',
          minWidth: '98%',
          height: '85vh',
          marginTop: '30px',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          padding: '10px 2px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Typography sx={{ marginLeft: '30px' }} variant="h4" component="h2" gutterBottom>
            고객 관리 리스트
          </Typography>
          <input
            type="text"
            placeholder="검색어를 입력하세요"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={{ padding: '5px', width: '500px', height: '40px' }}
          />
        </Box>

        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={10}
          onRowClick={handleDetailClick}
          style={{ height: '85%', width: '100%' }}
          onProcessRowUpdateError={(error) => {
            console.error('Error updating row:', error);
          }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              fontSize: '1.6rem', // 헤더 글자 크기 조절
            },
            '& .MuiDataGrid-cell': {
              fontSize: '1.5rem', // 셀 글자 크기 조절
            },
            '& .MuiTablePagination-select': {
              display: 'none'
            },
            '& .MuiTablePagination-selectLabel': {
              display: 'none'
            },
            '& .MuiTablePagination-actions': {
              fontSize: '1.8rem',
              marginRight: '15px'
            },
            '& .MuiTablePagination-displayedRows': {
              fontSize: '1.4rem', // 페이지 표시 폰트 크기 조정
            },
          }}
        />

      </Box>
    </Menu>
  );
}

export default Cmpage;
